<nav class="navbar relative">
  @if (isAuthenticated()) {
    <app-division-select></app-division-select>
  } @else {
    <button [routerLink]="MAIN_PAGE" tuiButton appearance="mono" size="m">
      <span class="text-orange-500">CUSTOMS</span>CLEAR
    </button>
  }

  <div class="absolute left-1/2 -translate-x-1/2">
    @for (item of availableMenuItems(); track $index) {
      <button
        (click)="navigate(item)"
        tuiButton
        appearance="mono"
        size="m"
        type="button"
      >
        {{ item.name }}
      </button>
    }
  </div>

  @if (isAuthenticated()) {
    <app-user-menu></app-user-menu>
  } @else {
    <button
      tuiIconButton
      appearance="outline"
      size="m"
      type="button"
      icon="tuiIconLogIn"
      [routerLink]="LOGIN_PAGE"
    ></button>
  }
</nav>
