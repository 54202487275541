<tui-hosted-dropdown [content]="content">
  <tui-avatar
    class="cursor-pointer"
    size="m"
    [style.--tui-avatar-background]="'var(--tui-accent-active)'"
    [style.--tui-avatar-color]="'white'"
    [text]="userFullName()"
  ></tui-avatar>
</tui-hosted-dropdown>
<ng-template #content>
  <tui-data-list role="menu">
    <tui-opt-group>
      <button routerLink="user" tuiOption>Учетная запись</button>
      <button (click)="logout()" tuiOption>
        Выйти
        <tui-svg src="tuiIconLogOut"></tui-svg>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
