import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { NgForOf } from '@angular/common';
import {
  TuiDataListModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiSelectModule } from '@taiga-ui/kit';
import { AuthState } from '@store/auth/auth.state';
import { Store } from '@ngxs/store';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { FormsModule } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { map, Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SetUserActiveDivision } from '@store/auth/auth.actions';
import { UserDivision } from '../../../types/auth';

@Component({
  selector: 'app-division-select',
  standalone: true,
  imports: [
    NgForOf,
    TuiDataListModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    FormsModule,
  ],
  templateUrl: './division-select.component.html',
  styleUrl: './division-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DivisionSelectComponent implements OnInit {
  userDivisions = this.store.selectSignal(AuthState.getUserDivisions);
  user = this.store.selectSignal(AuthState.getUser);

  changeDivision$ = new Subject<number>();
  constructor(
    private readonly store: Store,
    private readonly auth: AuthService,
    private readonly destroyRef: DestroyRef,
  ) {}

  stringify(
    items: readonly UserDivision[] | null,
  ): TuiStringHandler<TuiContextWithImplicit<number>> {
    const arr = items?.map(({ id, name }) => [id, name] as [number, string]);
    const map = new Map(arr);
    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  ngOnInit() {
    this.changeDivision$
      .pipe(
        switchMap(id => this.auth.changeActiveDivision(id).pipe(map(() => id))),
        switchMap(id => this.store.dispatch(new SetUserActiveDivision(id))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
