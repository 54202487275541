<div class="main-page">
  <div class="main-page__left-block">
    <div>
      <h1 class="main-page__title">
        <span class="text-orange-500">CUSTOMS</span><br />
        <span>CLEAR</span>
      </h1>
      <h2 class="main-page__subtitle">Таможенное <br />оформление <br /></h2>
    </div>
  </div>
  <div class="w-1/2 bg-stone-800 lg:h-full"></div>
</div>
