<tui-select
  tuiTextfieldSize="m"
  [ngModel]="user()?.activeDivisionId"
  (ngModelChange)="changeDivision$.next($event)"
  [valueContent]="stringify(userDivisions())"
  class="b-form w-[250px]"
  [tuiTextfieldLabelOutside]="true"
>
  <ng-template tuiDataList>
    <tui-data-list>
      <button *ngFor="let item of userDivisions()" tuiOption [value]="item.id">
        {{ item.name }}
      </button>
    </tui-data-list>
  </ng-template>
</tui-select>
