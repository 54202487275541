import {
  TuiAlertModule,
  TuiButtonModule,
  TuiDialogModule,
  TuiDialogService,
  TuiRootModule,
} from '@taiga-ui/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { defer, filter, fromEvent, switchMap, throwError } from 'rxjs';
import { WINDOW } from '@shared/providers/window.provider';
import { NAVIGATOR } from '@shared/providers/navigator.provider';
import { VersionAvailableComponent } from './core/components/version-available/version-available.component';
import { FooterComponent } from './core/footer/footer.component';
import { NavbarComponent } from './core/navbar/navbar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NavbarComponent,
    FooterComponent,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiButtonModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'ng-declarant';
  readonly WORKER_NOT_FOUND = 'Worker not found. Use HTTPS connection!';
  workerMessage$ = defer(() => {
    const worker = this.navigator?.serviceWorker ?? null;
    return worker
      ? fromEvent<MessageEvent>(worker, 'message')
      : throwError(() => new Error(this.WORKER_NOT_FOUND));
  });

  workerReady$ = defer(() => {
    const worker = this.navigator?.serviceWorker ?? null;
    return worker
      ? fromPromise(worker.ready)
      : throwError(() => new Error(this.WORKER_NOT_FOUND));
  }).pipe(
    filter(s => s !== null),
    takeUntilDestroyed(this.destroyRef),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly swUpdate: SwUpdate,
    @Inject(NAVIGATOR) private readonly navigator: Navigator,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
  ) {}

  showNotification() {
    return this.dialogs.open(
      new PolymorpheusComponent(VersionAvailableComponent),
      {
        size: 's',
        closeable: false,
        dismissible: false,
      },
    );
  }

  checkNewVersion() {
    this.swUpdate?.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        switchMap(() => this.showNotification()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  checkIfDocumentAlreadyOpened() {
    this.workerReady$.subscribe(worker => {
      worker?.active?.postMessage({ type: 'APP_INSTANCE_CREATED' });
    });
  }

  closeWindowIfDocumentAlreadyOpened() {
    this.workerMessage$.subscribe(event => {
      const { type, message } = event.data;
      if (type === 'DOCUMENT_ALREADY_OPENED') {
        this.window.alert(message);
        this.window.close();
      }
    });
  }

  ngOnInit() {
    this.checkIfDocumentAlreadyOpened();
    this.closeWindowIfDocumentAlreadyOpened();
    this.checkNewVersion();
  }
}
