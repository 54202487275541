import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';

import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthState } from '@store/auth/auth.state';
import { FormsModule } from '@angular/forms';
import { TuiSelectModule } from '@taiga-ui/kit';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NgForOf } from '@angular/common';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MENU_ITEMS } from './menu';
import {
  JOURNAL_PAGE,
  KANBAN_PAGE,
  LOGIN_PAGE,
  MAIN_PAGE,
} from '../../route-names';
import { DivisionSelectComponent } from '../components/division-select/division-select.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  imports: [
    TuiButtonModule,
    RouterLink,
    UserMenuComponent,
    TuiDataListModule,
    FormsModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiLetModule,
    NgForOf,
    DivisionSelectComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  protected readonly LOGIN_PAGE = LOGIN_PAGE;

  isAuthenticated = this.store.selectSignal(AuthState.isAuthenticated);

  availableMenuItems = computed(() => {
    return MENU_ITEMS.filter(menuItem => {
      if (menuItem?.authenticated) {
        return this.isAuthenticated();
      }
      return true;
    });
  });

  protected readonly MAIN_PAGE = MAIN_PAGE;
  protected readonly MENU_ITEMS = MENU_ITEMS;
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  navigate(item: { path: string }) {
    const currentRoute = this.router.url;
    if (
      [KANBAN_PAGE, JOURNAL_PAGE].includes(item.path) &&
      [KANBAN_PAGE, JOURNAL_PAGE].some(fragment =>
        currentRoute.startsWith('/'.concat(fragment)),
      )
    ) {
      const [, , documentType] = currentRoute.split('/');
      const path = `${item.path}/${documentType}`;
      this.router.navigate([path]);
    } else {
      this.router.navigate([item.path]);
    }
  }
}
