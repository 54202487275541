import { JOURNAL_PAGE, KANBAN_PAGE, NOMENCLATURE } from '../../route-names';

export const MENU_ITEMS = [
  {
    name: 'Журнал',
    authenticated: true,
    path: JOURNAL_PAGE,
  },
  {
    name: 'Канбан',
    authenticated: true,
    path: KANBAN_PAGE,
  },
  {
    name: 'ТНВЭД',
    authenticated: true,
    path: NOMENCLATURE,
  },
];
