import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';

@Component({
  selector: 'app-version-available',
  standalone: true,
  imports: [TuiSvgModule, TuiTextfieldControllerModule],
  templateUrl: './version-available.component.html',
  styleUrl: './version-available.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionAvailableComponent {}
