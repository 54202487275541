import { Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { isAuthenticatedGuard } from '@shared/guards/is-authenticated.guard';
import { LoginFormComponent } from './core/authentication/login-form/login-form.component';
import {
  DT_IMPORT_PAGE,
  DTS_PAGE,
  JOURNAL_PAGE,
  KANBAN_PAGE,
  LOGIN_PAGE,
  MAIN_PAGE,
  NOMENCLATURE,
  STATISTICS_PAGE,
  USER,
} from './route-names';
import { MainPageComponent } from './core/pages/main-page/main-page.component';
import { StatisticsState } from './modules/statistics/store/statistics.state';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { DocumentLayoutComponent } from './core/layouts/document-layout/document-layout.component';
import { DtImportState } from './modules/dt-import/store/dt-import.state';
import { DtsState } from './modules/dts/store/dts.state';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: MAIN_PAGE,
        component: MainPageComponent,
      },
      {
        path: LOGIN_PAGE,
        component: LoginFormComponent,
      },
      {
        path: KANBAN_PAGE,
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/kanban/routes').then(mod => mod.KANBAN_ROUTES),
      },
      {
        path: JOURNAL_PAGE,
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/journal/routes').then(mod => mod.JOURNAL_ROUTES),
      },
      {
        path: NOMENCLATURE,
        loadComponent: () =>
          import(
            './modules/nomenclature/pages/nomenclature-page/nomenclature-page.component'
          ).then(c => c.NomenclaturePageComponent),
        canActivate: [isAuthenticatedGuard],
      },
      {
        path: USER,
        loadComponent: () =>
          import('./modules/user/pages/user-edit/user-edit.component').then(
            c => c.UserEditComponent,
          ),
        canActivate: [isAuthenticatedGuard],
      },
    ],
  },
  {
    path: '',
    component: DocumentLayoutComponent,
    children: [
      {
        path: STATISTICS_PAGE.concat('/:id'),
        loadComponent: () =>
          import(
            './modules/statistics/pages/statistics-detail-page/statistics-detail-page.component'
          ).then(c => c.StatisticsDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([StatisticsState])],
      },
      {
        path: DT_IMPORT_PAGE.concat('/:id'),
        loadComponent: () =>
          import(
            './modules/dt-import/pages/dt-import-detail-page/dt-detail-page.component'
          ).then(c => c.DtDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([DtImportState])],
      },
      {
        path: DTS_PAGE.concat('/:id'),
        loadComponent: () =>
          import(
            './modules/dts/pages/dts-detail-page/dts-detail-page.component'
          ).then(c => c.DtsDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([DtsState])],
      },
    ],
  },
  {
    path: '**',
    redirectTo: KANBAN_PAGE,
  },
];
